import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Flex } from "antd";

import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";

import { AdyenPaymentDepositFilter, FilterConditions } from "../../AdyenPaymentDepositFilter";
import { Shop } from "../../types";

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

type Props = {
  defaultFilterConditions: FilterConditions;
  shopIds: string[];
  getShopDetail: ({ shopId }: { shopId: string }) => Shop | undefined;
  onDownload: ({ shopIds }: { shopIds: string[] }) => void;
};

export const ExportAdyenRemittanceResultByTransactionCsv = memo<Props>(
  ({ shopIds, defaultFilterConditions, getShopDetail, onDownload }) => {
    const { filterConditions, updateFilterCondition } =
      useFilterConditions<FilterConditions>(defaultFilterConditions);

    const handleDownload = useCallback(() => {
      onDownload({ shopIds: filterConditions.shopIds || [] });
    }, [filterConditions.shopIds, onDownload]);

    return (
      <>
        <Title>取引ごとの内訳をダウンロード</Title>
        <Description>
          1回の入金において、対象店舗を複数選択し、入金額の取引ごとの内訳をダウンロードできる
        </Description>
        <Spacer size={16} />
        <AdyenPaymentDepositFilter
          shopIds={shopIds}
          getShopDetail={getShopDetail}
          filterConditions={filterConditions}
          updateFilterCondition={updateFilterCondition}
        />
        <Spacer size={16} />
        <Flex justify="end">
          <Button type="primary" onClick={handleDownload}>
            CSVダウンロード
          </Button>
        </Flex>
      </>
    );
  },
);
