import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Modal } from "antd";

import { FilterConditions } from "../AdyenPaymentDepositFilter";
import { Shop } from "../types";

import { ExportAdyenPaymentDepositHistoryByShopCsv } from "./ExportAdyenPaymentDepositHistoryByShopCsv";
import { ExportAdyenRemittanceResultByTransactionCsv } from "./ExportAdyenRemittanceResultByTransactionCsv";

type Props = {
  open: boolean;
  shopIds: string[];
  defaultFilterConditions: FilterConditions;
  getShopDetail: ({ shopId }: { shopId: string }) => Shop | undefined;
  onDownloadAdyenRemittanceResultByShop: ({ shopIds }: { shopIds: string[] }) => void;
  onDownloadAdyenRemittanceResultByTransactionQuery: ({ shopIds }: { shopIds: string[] }) => void;
  onClose: () => void;
};

const StyledModal = styled(Modal)`
  min-width: max(520px, 80%);
`;

export const ExportAdyenPaymentDepositHistoryCsvModal = memo<Props>(
  ({
    open,
    shopIds,
    defaultFilterConditions,
    getShopDetail,
    onDownloadAdyenRemittanceResultByShop,
    onDownloadAdyenRemittanceResultByTransactionQuery,
    onClose,
  }) => {
    const onExportAdyenPaymentDepositHistoryByShopCsv = useCallback(
      ({ shopIds }: { shopIds: string[] }) => {
        onDownloadAdyenRemittanceResultByShop({ shopIds });
        onClose();
      },
      [onDownloadAdyenRemittanceResultByShop, onClose],
    );

    const onExportAdyenRemittanceResultByTransactionQueryCsv = useCallback(
      ({ shopIds }: { shopIds: string[] }) => {
        onDownloadAdyenRemittanceResultByTransactionQuery({ shopIds });
        onClose();
      },
      [onDownloadAdyenRemittanceResultByTransactionQuery, onClose],
    );

    return (
      <StyledModal title="CSVダウンロード" open={open} onCancel={onClose} footer={null}>
        <ExportAdyenPaymentDepositHistoryByShopCsv
          shopIds={shopIds}
          getShopDetail={getShopDetail}
          defaultFilterConditions={defaultFilterConditions}
          onDownload={onExportAdyenPaymentDepositHistoryByShopCsv}
        />
        <ExportAdyenRemittanceResultByTransactionCsv
          shopIds={shopIds}
          getShopDetail={getShopDetail}
          defaultFilterConditions={defaultFilterConditions}
          onDownload={onExportAdyenRemittanceResultByTransactionQueryCsv}
        />
      </StyledModal>
    );
  },
);
